import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { Sink } from '../sink'

const Si = () => {
	return (
		<L10nProvider>
			<Sink />
		</L10nProvider>
	)
}

export default Si;